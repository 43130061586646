import React, { useState } from 'react';
import Nav from '../../components/Nav';
import TablaJuzgado from './TablaJuzgado';
import Footer from '../../components/Footer/Footer.jsx';
import { Box, Tab } from '@mui/material';
import { TabList, TabPanel, TabContext } from '@mui/lab';
import '../abogado/inicio/InicioAbogado.css';
import decodeToken from '../../connections/decodeToken';

const InicioJuzgado = () => {
	const [value, setValue] = useState('1');
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const usuario = decodeToken(localStorage.getItem('token')).usuario || "Juez"

	return (
		<div>
			<Nav />
			<Box className='contenedorTabla'>
				<h2 className='titulo'>Bienvenido {usuario}</h2>
				<TabContext value={value}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleChange} aria-label='lab API tabs example'>
							<Tab label='Recibidas' value='1' />
							<Tab label='A recibir' value='2' />
						</TabList>
					</Box>
					<TabPanel value='1'>
						<TablaJuzgado tipo={value} />
					</TabPanel>
					<TabPanel value='2'>
						<TablaJuzgado tipo={value} />
					</TabPanel>
				</TabContext>
			</Box>
			<Footer />
		</div>
	);
};

export default InicioJuzgado;
