import React from 'react';
import { Box, Button, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ResponsiveAppBar from '../../../components/Nav';
import { useNavigate } from 'react-router-dom';
import './NuevaDemanda.css';
import { obtenerJuzgados } from '../../../connections/juzgados.js';
import { crearCausa } from '../../../connections/causas.js';
import RenderToast from '../../../components/Utils/RenderToast';

export default function NuevaDemanda() {
	const navigate = useNavigate();
	const [status, setStatus] = React.useState(0);
	const [juzgadosDisponibles, setJuzgados] = React.useState([]);
	const [demanda, setDemanda] = React.useState({
		juzgado: '',
		nombre: '',
		file: '',
		id_juzgado: 1,
	});

	React.useEffect(() => {
		if (!juzgadosDisponibles.length) {
			async function cargarJuzgados() {
				const { data } = await obtenerJuzgados();
				let { juzgados } = data;
				juzgados = juzgados.filter((juzgado) => {
					return juzgado.nombre.includes('1ra');
				});

				setJuzgados(juzgados || [false]);
			}
			cargarJuzgados();
		}
	});

	React.useEffect(() => {
		if (status === 201) {
			setTimeout(() => {
				return navigate(-1);
			}, 1000 * 1);
		}
	}, [status, navigate]);

	/**
	 * Esta función es la encargada de realizar la actualización del documento utilizando el estado de React
	 * @param {Evento} e recibe un evento que sucede en el botón de guardar actualización
	 * @return {void} void
	 */
	async function manejarGuardar(e) {
		e.preventDefault();
		try {
			const consult = await crearCausa(demanda);
			setStatus(consult.status);

			setTimeout(() => {
				setStatus(0);
			}, 1000 * 1);
		} catch (error) {
			setStatus(500);
		}
	}

	const manejadorDeCambios = (e) => {
		e.preventDefault();
		if (e.target.name === 'juzgado') return manejarJuzgado(e.target.value);
		setDemanda({ ...demanda, [e.target.name]: e.target.value });
	};

	const manejarJuzgado = (juzgado) => {
		setDemanda({ ...demanda, id_juzgado: findIDJuzgado(juzgado), juzgado: juzgado });
	};

	function findIDJuzgado(nombre) {
		if (juzgadosDisponibles.length) {
			const juzgado = juzgadosDisponibles.find((juzgado) => juzgado.nombre === nombre);
			return juzgado?.id;
		}
		return 0;
	}

	function manejarArchivo(e) {
		e.preventDefault();

		setDemanda({ ...demanda, file: e.target.files[0] });
	}

	return (
		<div>
			{RenderToast(status, status === 201 ? 'Demanda creada con éxito' : 'Fallo al crear')}
			<ResponsiveAppBar />
			<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 5 }}>
				<Button onClick={() => navigate(-1)}>
					<ArrowBackIcon />
				</Button>
				<h2>Nueva Demanda</h2>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					m: 1,
					minWidth: 200,
					maxWidth: '400px',
					width: '100%',
					// Esto lo puse de prueba para que no me quede pegado a la izquierda
					ml: 'auto',
					mr: 'auto',
				}}
			>
				<InputLabel id='demo-simple-select-helper-label'>
					Ingrese el nombre de la demanda
				</InputLabel>
				<TextField
					sx={{ my: 2, minWidth: 200, maxWidth: '400px' }}
					name='nombre'
					required
					//value={usuarioAeditar && usuarioAeditar.mail}
					placeholder={'Nombre'}
					label='Nombre'
					variant='outlined'
					autoComplete='off'
					value={demanda.nombre}
					onChange={manejadorDeCambios}
				/>
				<InputLabel id='demo-simple-select-helper-label'>Seleccionar Fuero</InputLabel>
				<Select
					sx={{ my: 2, minWidth: 200, maxWidth: '400px' }}
					labelId='demo-simple-select-helper-label'
					id='demo-simple-select-helper'
					name='juzgado'
					value={demanda.juzgado}
					onChange={(e) => manejadorDeCambios(e)}
					required
				>
					{juzgadosDisponibles &&
						juzgadosDisponibles.map((juzgado) => {
							return (
								<MenuItem value={juzgado.nombre} key={juzgado.id}>
									{juzgado.nombre}
								</MenuItem>
							);
						})}
				</Select>

				<br />
				{/* <h3 className='subirArchivo'>Subir archivo</h3> */}
				<Box
					sx={{
						my: 2,
						height: 50,
						minWidth: 200,
						maxWidth: '400px',
					}}
					className=' inputBox'
				>
					<input
						type='file'
						name=''
						id='outlined-basic'
						sx={{
							width: '100%',
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
						}}
						onChange={(e) => manejarArchivo(e)}
						className='inputfile inputfile-1'
					/>
				</Box>
				{demanda.file && (
					<h5 style={{ textAlign: 'center', width: '100%', margin: '0 10px' }}>
						El archivo {demanda.file.name} se cargó correctamente.
					</h5>
				)}
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-around',
						marginTop: '5vh',
					}}
				>
					<Button variant='contained' color='info' onClick={(e) => manejarGuardar(e)}>
						GUARDAR
					</Button>
					<Button variant='contained' color='error' onClick={() => navigate(-1)}>
						CANCELAR
					</Button>
				</Box>
			</Box>
		</div>
	);
}
