import {
	Box,
	Button,
	FormControl,
	IconButton,
	InputLabel,
	List,
	ListItem,
	ListItemText,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import './Causa.css';
import { causaContext } from '../../routes/juzgado/TablaJuzgado.jsx';
import { restringirUsuarios, tiposPosibles } from '../../connections/decodeToken.js';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useContext, useEffect, useState } from 'react';
import { ingresarCausa, obtenerDetallesCausa } from '../../connections/causas';
import { obtenerAbogados } from '../../connections/conexionesUsuario';
import renderToast from '../../components/Utils/RenderToast';

export default function IngresarCausa({ id }) {
	restringirUsuarios(tiposPosibles.juez);
	const [status, setStatus] = useState(0);
	const { setMostrarIngresarCausa } = useContext(causaContext);
	const [causa, setCausa] = useState(undefined);
	const [listaAbogados, setListaAbogados] = useState([]);
	// Abogados que ya pertenecen a la causa
	const [abogadosDeCausa, setAbogadosDeCausa] = React.useState([]);
	const [initialAbogados, setInitialAbogados] = useState([]);
	const [info, setInfo] = useState({
		caratula: '',
		expediente: '',
	});

	useEffect(() => {
		if (!causa) {
			obtenerDetallesCausa(id).then(({ data }) => {
				setCausa(data);
				setAbogadosDeCausa(data.abogados || []);
			});
		}
	});

	useEffect(() => {
		if (!initialAbogados.length) {
			obtenerAbogados().then(({ data }) => {
				setListaAbogados(data.users);
				setInitialAbogados(data.users);
			});
		}
	});

	useEffect(() => {
		eliminarDuplicados();
	}, [abogadosDeCausa, initialAbogados]);

	const eliminarDuplicados = () => {
		abogadosDeCausa.forEach((abogadoDeCausa) => {
			setListaAbogados(
				listaAbogados.filter((abogado) => abogado.usuario !== abogadoDeCausa.usuario)
			);
		});
	};

	function manejarInfoCausa(e) {
		setInfo({ ...info, [e.target.id]: e.target.value });
	}

	function ingresarAbogado(e) {
		return setAbogadosDeCausa([...abogadosDeCausa, e.target.value]);
	}

	function eliminarAbogadoDeOpciones(e) {
		return setListaAbogados(
			listaAbogados.filter((abogado) => abogado.usuario !== e.target.value.usuario)
		);
	}

	function eliminarAbogadoSeleccionado(abogado) {
		// 1. Eliminar de abogadosDeCausa
		setAbogadosDeCausa(abogadosDeCausa.filter((abogadoDeCausa) => abogadoDeCausa !== abogado));

		// 2 Añadir a listaAbogados
		setListaAbogados([...listaAbogados, abogado]);
	}

	async function ingresarCausaC(e) {
		e.preventDefault();
		const actualizacion = {
			caratula: info.caratula,
			expediente: info.expediente,
			abogados: abogadosDeCausa.map((abogado) => {
				return abogado.id;
			}),
		};
		const consult = await ingresarCausa(id, actualizacion);

		setStatus(consult?.status || 400);

		setTimeout(() => {
			if (status === 200) setMostrarIngresarCausa(false);
			setStatus(0);
		}, 1000 * 1);
	}

	useEffect(() => {
		if (status === 200) {
			setTimeout(() => {
				setMostrarIngresarCausa(false);
			}, 1000 * 1);
		}
	}, [status, setMostrarIngresarCausa]);

	return (
		<div className='contenedor'>
			{renderToast(
				status,
				status === 200
					? 'Actualizado con éxito'
					: 'Algo salió mal, ponte en contacto con la administración'
			)}
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					my: 1,
					mx: 5,
					px: 5,
					minWidth: 200,
					maxWidth: '600px',
					width: '100%',
				}}
				className='contenedorPopUp'
			>
				<h2>
					Ingresar Expediente {'<'}
					{id}
					{'>'}
				</h2>
				<TextField
					sx={{ my: 2, minWidth: 200, maxWidth: '600px' }}
					id='caratula'
					label='Carátula'
					variant='outlined'
					autoComplete='off'
					onChange={(e) => manejarInfoCausa(e)}
				/>
				<TextField
					sx={{
						my: 2,
						minWidth: 200,
						maxWidth: '400px',
					}}
					id='expediente'
					label='Asignar Nº de Expediente'
					variant='outlined'
					autoComplete='off'
					onChange={(e) => manejarInfoCausa(e)}
				/>
				<FormControl sx={{ my: 2, minWidth: 200 }}>
					<InputLabel>Abogados</InputLabel>
					<Select
						sx={{ minWidth: 200, maxWidth: '400px' }}
						labelId='demo-simple-select-helper-label'
						id='type'
						label='Abogados'
						value={''}
						onChange={(e) => {
							ingresarAbogado(e);
							eliminarAbogadoDeOpciones(e);
						}}
						required
					>
						{listaAbogados &&
							listaAbogados.map((abogado, index) => {
								return (
									<MenuItem value={abogado} key={index + 1}>
										{abogado.usuario}
									</MenuItem>
								);
							})}
					</Select>
				</FormControl>

				<Box item xs={12} sx={{ my: 1, minWidth: 200, maxWidth: '400px' }}>
					<List>
						{abogadosDeCausa.length
							? abogadosDeCausa.map((abogado, index) => (
								<ListItem
									key={index}
									secondaryAction={
										<IconButton
											edge='end'
											aria-label='delete'
											onClick={() => eliminarAbogadoSeleccionado(abogado)}
										>
											<DeleteIcon />
										</IconButton>
									}
								>
									<ListItemText primary={abogado.usuario} />
								</ListItem>
							))
							: ''}
					</List>
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-around',
						minWidth: 200,
						maxWidth: '600px',
						my: 4,
					}}
				>
					<Button variant='contained' color='info' onClick={(e) => ingresarCausaC(e)}>
						GUARDAR
					</Button>
					<Button variant='contained' color='error' onClick={() => setMostrarIngresarCausa(false)}>
						CANCELAR
					</Button>
				</Box>
			</Box>
		</div>
	);
}
