import React from 'react';
import { Button, Box } from '@mui/material';
import Nav from '../../components/Nav.jsx';
import img from '../../404.png';
import { useNavigate } from 'react-router-dom';

export default function ErrorPage() {
	const navigate = useNavigate();
	return (
		<Box
			style={{ overflowY: 'hidden' }}
			sx={{
				height: '100vh',
			}}
		>
			<Nav />
			<Box
				sx={{
					backgroundColor: 'rgba(0, 0, 0, 0.15)',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					height: '94vh',
				}}
			>
				<img src={img} alt='Img errorPage' style={{ width: '300px' }} />
				<h1>Ups! Ha ocurrido un error inesperado.</h1>
				<h4>No eres tú, somos nosotros (probablemente)...</h4>
				<Button variant='contained' color='error' onClick={(e) => navigate(-1)}>
					VOLVER ATRAS
				</Button>
			</Box>
		</Box>
	);
}
