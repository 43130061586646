import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Box } from '@mui/material';

import LinearProgress from '@mui/material/LinearProgress';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export default function CustomizedSnackbars({ severity, mensaje, progress }) {
	const activeSeconds = 3 * 1000;

	return (
		<Snackbar open={true} autoHideDuration={activeSeconds}>
			<Alert severity={severity}>
				<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
					{mensaje}
					{progress && <LinearProgress color='inherit' />}
				</Box>
			</Alert>
		</Snackbar>
	);
}
