import axios from 'axios';

const API_URL = process.env.REACT_APP_SPJ_API;

const config = {
	headers: {
		Authorization: localStorage.getItem('token'),
	},
};

export async function obtenerCausas(filtrosQuery) {
	try {
		const consult = await axios.get(`${API_URL}/causas${filtrosQuery}`, config);
		return consult;
	} catch (error) {
		return error;
	}
}

export async function obtenerDetallesCausa(id) {
	try {
		const consult = await axios.get(`${API_URL}/causas/${id}`, config);
		return consult;
	} catch (error) {
		return error;
	}
}

export async function crearCausa(causa) {
	try {
		const consult = await axios.post(`${API_URL}/causas`, causa, {
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: localStorage.getItem('token'),
			},
		});
		return consult;
	} catch (error) {
		return error;
	}
}

export async function nuevoDocumento(documento) {
	try {
		const consult = await axios.post(`${API_URL}/documentos`, documento, {
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: localStorage.getItem('token'),
			},
		});
		return consult;
	} catch (error) {
		return error;
	}
}
export async function obtenerCausasJuez(filter) {
	try {
		const consult = await axios.get(`${API_URL}/causas?${filter}`, config);
		return consult;
	} catch (error) {
		return error;
	}
}

export async function ingresarCausa(id, info) {
	try {
		const consult = await axios.patch(`${API_URL}/causas/${id}`, info, config);
		return consult;
	} catch (error) {
		return error;
	}
}

export async function ontenerInfoDeCausa(id) {
	try {
		const consult = await axios.get(`${API_URL}/causas/${id}`, config);
		return consult;
	} catch (error) {
		return error;
	}
}

export async function nuevoPase(id, idDestino) {
	try {
		const pase = await axios.post(`${API_URL}/pases/${id}`, { id_destino: idDestino }, config);

		return pase;
	} catch (error) {
		return error;
	}
}

export async function modificarAbogados(id, abogados) {
	try {
		const idAbogados = abogados.map((abogado) => abogado.id);

		const modificaAbogados = await axios.patch(
			`${API_URL}/causas/${id}`,
			{ abogados: idAbogados },
			config
		);
		return modificaAbogados;
	} catch (error) {
		return error;
	}
}

export async function eliminarCausa(id) {
	try {
		const causaEliminada = await axios.delete(`${API_URL}/causas/${id}`, config);
		return causaEliminada;
	} catch (error) {
		return error;
	}
}

export async function modificarVisto(id, setInfoCausa) {
	try {
		const consult = await axios.post(`${API_URL}/documentos/visto/${id}`, '', config);
		setInfoCausa(undefined);

		return consult;
	} catch (error) {
		return error;
	}
}
