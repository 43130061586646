import React from 'react';
import { Box } from '@mui/material';

export default function CirculoRojo({ notificaciones }) {
	return (
		<div>
			{notificaciones > 0 && (
				<Box
					// className='puntoRojo'
					sx={{
						bgcolor: 'red',
						color: 'white',
						borderRadius: '50%',
						fontSize: '0.7rem',
						height: '1rem',
						width: '1rem',
						zIndex: 'tooltip',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					{notificaciones}
				</Box>
			)}
		</div>
	);
}
