import React from "react";
import TablaPaginado from "./TablaPaginado";
import Buscador from "../Buscador/Buscador.jsx";
import { useNavigate, Link } from "react-router-dom";
import {
  obtenerTipoDeUsuario,
  tiposPosibles,
} from "../../connections/decodeToken";
import * as dateParser from "../Utils/DateParser.js";
import {
  TablePagination,
  TableFooter,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { modificarVisto } from "../../connections/causas";
import { TableSkeleton } from "../TableSkeleton/TableSkeleton";

function TablaCausa({
  setInfoCausa,
  tipo,
  archivos,
  id,
  render,
  setRender,
  loading,
}) {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [busqueda, setBusqueda] = React.useState("");

  const filtrarArchivos = () => {
    if (!archivos) return [];
    switch (tipo) {
      case "ACTUACION":
        return archivos.filter((a) => a.type === "ACTUACION");
      case "PRUEBA":
        return archivos.filter((a) => a.type === "PRUEBA");
      case "NOTIFICACION":
        return archivos.filter((a) => a.type === "NOTIFICACION");
      case "BORRADOR":
        return archivos.filter((a) => a.type === "BORRADOR");
      default:
        break;
    }
  };

  const [archivosPaginado, setArchivosPaginado] = React.useState([]);
  const [archivosAMostrar, setArchivosAMostrar] = React.useState([]);

  React.useEffect(() => {
    setArchivosPaginado(
      filtrarArchivos().slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
    );
  }, [page, rowsPerPage, loading]);

  React.useEffect(() => {
    if (busqueda && busqueda.length) {
      setArchivosAMostrar(
        archivosPaginado.filter((archivo) => {
          return archivo.nombre.includes(busqueda);
        })
      );
    } else {
      setArchivosAMostrar(archivosPaginado);
    }
  }, [busqueda, archivosPaginado, archivos, loading]);

  //  --------------- Paginado ---------------
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // --------------- Componente ---------------

  function renderArchivos(archivos) {
    if (!archivos || !archivos.length) {
      return (
        <TableRow>
          <TableCell colSpan={5} sx={{ display: "flex", alignItems: "center" }}>
            <h4 style={{ marginRight: "10px", fontWeight: "normal" }}>
              No hay Documentos para mostrar, podés cargar el primero con el
              botón <span style={{ fontWeight: "bold" }}>"Nuevo Archivo"</span>{" "}
              o presionando
              <Link
                to={`/causa/${id}/nuevoarchivo`}
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {" "}
                aquí
              </Link>
            </h4>
          </TableCell>
        </TableRow>
      );
    }

    return archivos.map((documento, index) => {
      const formatoFecha = dateParser.getTerm(
        documento.created_at,
        documento.notificacion?.tipo
      );
      return (
        <TableRow
          key={`${index}${Math.floor(Math.random() * 100)}`}
          className="tableRowDocCausa"
        >
          <TableCell align="left">
            {dateParser.getDate(documento.created_at)}
          </TableCell>
          <TableCell align="left">{documento.nombre}</TableCell>
          <TableCell align="left">{documento.created_by.usuario}</TableCell>
          {tipo === "NOTIFICACION" && (
            <TableCell align="center">{documento.notificacion?.tipo}</TableCell>
          )}
          {tipo === "NOTIFICACION" && (
            <TableCell align="center">
              {formatoFecha > 0 ? `${formatoFecha} días` : "Plazo alcanzado"}
            </TableCell>
          )}
          <TableCell align="center">
            <Box
              style={{
                height: "100%",
                width: "100%",
                position: "relative",
              }}
            >
              <Link
                to={documento.ruta}
                target="blank"
                rel="noopener noreferrer"
              >
                <VisibilityIcon
                  color="info"
                  onClick={() => {
                    if (
                      obtenerTipoDeUsuario(localStorage.getItem("token")) ===
                      tiposPosibles.abogado &&
                      documento.notificacion.visto === false
                    )
                      modificarVisto(documento.id, setInfoCausa);
                  }}
                />
                {documento.notificacion?.visto === false && (
                  <Box
                    className="puntoRojo"
                    sx={{
                      bgcolor: "red",
                      color: "white",
                      borderRadius: "50%",
                      fontSize: "0.7rem",
                      position: "absolute",
                      height: "0.5rem",
                      width: "0.5rem",
                      // top: 15,
                      top: 0,
                      // right: 0,
                      bottom: 0,
                      left: "50%",
                      zIndex: "tooltip",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // zIndex: 1,
                    }}
                  ></Box>
                )}
              </Link>
            </Box>
          </TableCell>
          {tipo === "BORRADOR" && (
            <TableCell align="center">
              <Link to="/editararchivo">
                <EditIcon color="info" />
              </Link>
            </TableCell>
          )}
        </TableRow>
      );
    });
  }
  return (
    <Box>
      <Box className="botonNuevaDemanda">
        <Buscador busqueda={busqueda} setBusqueda={setBusqueda} />
        <Button
          variant="contained"
          color="info"
          onClick={() => navigate(`/causa/${id}/nuevoarchivo`)}
        >
          Nuevo Archivo
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Fecha de creación</TableCell>
              <TableCell align="left">Nombre</TableCell>
              <TableCell align="left">Autor</TableCell>
              {tipo === "NOTIFICACION" && (
                <TableCell align="center">Tipo Notificación</TableCell>
              )}
              {tipo === "NOTIFICACION" && (
                <TableCell align="center">Plazo</TableCell>
              )}
              <TableCell align="center">Visualizar</TableCell>
              {tipo === "BORRADOR" && (
                <TableCell align="center">Editar</TableCell>
              )}
              {tipo === "5" && (
                <TableCell align="center">Habilitados</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableSkeleton columnas={5} filas={5} />
            ) : (
              renderArchivos(archivosAMostrar)
            )}
          </TableBody>
          <TableFooter className="tablePaginado">
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[2, 5, 10, 25]}
                colSpan={6}
                count={filtrarArchivos().length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage="Documentos por página"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablaPaginado}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default TablaCausa;
