/* eslint-disable react/style-prop-object */
import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Nav from "../../components/Nav.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import TablaCausa from "../../components/Tablas/TablaDocumentosCausa.jsx";
import TablaPasesAbogados from "../../components/Tablas/TablaPasesAbogados.jsx";
import { obtenerDetallesCausa } from "../../connections/causas.js";
import { Box, Button, Skeleton, Tab } from "@mui/material";
import { TabList, TabPanel, TabContext } from "@mui/lab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircularProgress from "@mui/material/CircularProgress";
import CirculoRojo from "../../components/Alert&Toast/CirculoRojo.jsx";

const Causa = (juzgado) => {
  //EL ID DE UNA CAUSA
  const { id } = useParams();
  const navigate = useNavigate();
  const [infoCausa, setInfoCausa] = React.useState(undefined);
  const [value, setValue] = useState("1");
  const [notificaciones, setNotificaciones] = React.useState(0);
  const [render, setRender] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  // !Arreglar posible bucle infinito en el caso de que no se encuentre la Causa
  React.useEffect(() => {
    if (!infoCausa) {
      setLoading(true);
      async function cargarInformacion() {
        const { data } = await obtenerDetallesCausa(id);
        setInfoCausa(data || { info: "Not loaded" });
        setLoading(false);
      }
      cargarInformacion();
    }
  }, [infoCausa, id]);

  React.useEffect(() => {
    if (infoCausa) {
      function numNotificaciones() {
        setNotificaciones(
          infoCausa.documentos?.filter(
            (documento) =>
              documento.type === "NOTIFICACION" &&
              documento.notificacion.visto === false
          ).length || 0
        );
      }
      numNotificaciones();
    }
  }, [infoCausa]);

  /**
   * Should update causa info when a notification was viewed
   */
  React.useEffect(() => {
    if (render !== 0) {
      setLoading(true);
      async function cargarInformacion() {
        const { data } = await obtenerDetallesCausa(id);
        setInfoCausa(data || [{ info: "Not loaded" }]);
        setLoading(false);
      }
      cargarInformacion();
    }
  }, [render, notificaciones]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (infoCausa && infoCausa.info && infoCausa.info === "Not loaded") {
    return (
      <Box>
        <Nav />
        <Box
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.15)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress sx={{ width: "450px", height: "450px" }} />
          <h1>No encontramos la causa que está buscando</h1>
          <h4>
            Intente buscando una diferente. En el caso de que continúe el error,
            póngase en contacto con sistemas.
          </h4>
          <Button variant="contained" color="info" onClick={() => navigate(-1)}>
            VOLVER ATRAS
          </Button>
        </Box>
      </Box>
    );
  }


  return (
    <div>
      <Nav />
      <Box className="containerCausa">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            mb: 5,
          }}
        >
          <Button onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </Button>
          <h2 style={{ marginLeft: "10px" }}>
            {infoCausa?.expediente || <Skeleton width={100} />}
          </h2>
          <h2 style={{ marginLeft: "10px" }}>
            {infoCausa?.caratula || <Skeleton width={200} />}
          </h2>
        </Box>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Actuaciones" value="1" />
              <Tab label="Prueba" value="2" />
              <Tab
                label="Notificaciones"
                value="3"
                icon={<CirculoRojo notificaciones={notificaciones} />}
                iconPosition="end"
              ></Tab>
              <Tab label="Borrador" value="4" />
              {juzgado && <Tab label="Pases" value="5" />}
              {juzgado && <Tab label="Abogados" value="6" />}
            </TabList>
          </Box>

          <TabPanel value="1">
            <TablaCausa
              loading={loading}
              tipo="ACTUACION"
              archivos={infoCausa?.documentos}
              id={id}
            />
          </TabPanel>
          <TabPanel value="2">
            <TablaCausa
              tipo="PRUEBA"
              archivos={infoCausa?.documentos}
              id={id}
              loading={loading}
            />
          </TabPanel>
          <TabPanel value="3">
            <TablaCausa
              tipo="NOTIFICACION"
              archivos={infoCausa?.documentos}
              infoCausa={infoCausa}
              setInfoCausa={setInfoCausa}
              id={id}
              render={render}
              setRender={setRender}
              loading={loading}
            />
          </TabPanel>
          <TabPanel value="4">
            <TablaCausa
              tipo="BORRADOR"
              infoCausa={infoCausa}
              archivos={infoCausa?.documentos}
              id={id}
              render={render}
              setRender={setRender}
              loading={loading}
            />
          </TabPanel>
          <TabPanel value="5">
            <TablaPasesAbogados
              tipo="5"
              pases={infoCausa?.pases}
              idCausa={infoCausa?.id}
              render={render}
              setRender={setRender}
              id={id}
              loading={loading}
            />
          </TabPanel>
          <TabPanel value="6">
            <TablaPasesAbogados
              tipo="6"
              abogados={infoCausa?.abogados}
              idCausa={infoCausa?.id}
              id={id}
              loading={loading}
            />
          </TabPanel>
        </TabContext>
      </Box>

      <Footer />
    </div>
  );
};
export default Causa;
