import React from 'react';
import { Button, Box } from '@mui/material';
import Nav from '../../components/Nav.jsx';
import { useNavigate } from 'react-router-dom';

import astronaut from '../../Assets/astronaunt.png';

const PaginaNoEncontrada = () => {
	const navigate = useNavigate();
	return (
		<Box
			style={{ overflowY: 'hidden' }}
			sx={{
				height: '100vh',
			}}
		>
			<Nav />
			<Box
				sx={{
					backgroundColor: 'rgba(0, 0, 0, 0.15)',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					height: '94vh',
				}}
			>
				<img src={astronaut} alt='astronaut lost in space' style={{ width: '300px' }} />
				<h1>¡Ups! Parece que estás perdido.</h1>
				<h4>Lo que estás buscando no está disponible</h4>
				<Button variant='contained' color='error' onClick={() => navigate('/')}>
					VUELVE AL INICIO
				</Button>
			</Box>
		</Box>
	);
};

export default PaginaNoEncontrada;
