import React, { useState } from 'react';
import ResponsiveAppBar from '../../components/Nav';
import Tabla from '../../components/Tablas/TablaCausas';
import TablaUsuarios from '../../components/Tablas/TablaUsuarios';
import Footer from '../../components/Footer/Footer.jsx';
import { restringirUsuarios, tiposPosibles } from '../../connections/decodeToken';
import { Box, Tab } from '@mui/material';
import { TabList, TabPanel, TabContext } from '@mui/lab';
import '../abogado/inicio/InicioAbogado.css';

const InicioAdmin = () => {
	restringirUsuarios([tiposPosibles.admin]);
	const [value, setValue] = useState('1');

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div>
			<ResponsiveAppBar />
			<Box className='contenedorTabla'>
				<h2 className='titulo'>Bienvenido Admin</h2>
				<TabContext value={value}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleChange} aria-label='lab API tabs example'>
							<Tab label='Usuarios' value='1' />
							<Tab label='Causas' value='2' />
						</TabList>
					</Box>
					<TabPanel value='1'>
						<TablaUsuarios />
					</TabPanel>
					<TabPanel value='2'>
						<Tabla admin={'admin'} />
					</TabPanel>
				</TabContext>
			</Box>
			<Footer />
		</div>
	);
};

export default InicioAdmin;
