import axios from 'axios';

const API_URL = process.env.REACT_APP_SPJ_API;

const config = {
	headers: {
		Authorization: localStorage.getItem('token'),
	},
};

export async function login(user) {
	try {
		return await axios.post(`${API_URL}/usuarios/login`, user);
	} catch (error) {
		return {
			status: error.response.status || 500,
		};
	}
}

export async function crearUsuario(user) {
	try {
		return await axios.post(`${API_URL}/usuarios`, user, config);
	} catch (error) {
		return {
			status: error.response.status,
		};
	}
}

export async function borrarUsuario(id) {
	try {
		return await axios.delete(`${API_URL}/usuarios?ids=${JSON.stringify([id])}`, config);
	} catch (error) {
		return {
			status: error.response.status,
		};
	}
}

/**
 * Como admin obtiene los usuarios, por query puede ordenar y filtrar.
 * @require User tipo admin
 * @params Options Object: { limit, offset, sort, order, filter }
 */

export async function obtenerUsuarios(filtrosQuery) {
	try {
		const consult = await axios.get(`${API_URL}/usuarios${filtrosQuery}`, config);
		return consult;
	} catch (error) {
		return error;
	}
}

export async function obtenerUnUsuario(id) {
	try {
		const consulta = await axios.get(`${API_URL}/usuarios/${id}`);
		return consulta;
	} catch (error) {
		return {
			status: error.response.status,
		};
	}
}

export async function eliminarUsuario(ids) {
	try {
		const consulta = await axios.delete(`${API_URL}/usuarios`, ids);
	} catch (error) {
		return {
			status: error.response.status,
		};
	}
}

export async function actualizarUsuario(id, user) {
	try {
		const consulta = await axios.put(`${API_URL}/usuarios/${id}`, user, config);
		return consulta;
	} catch (error) {
		return {
			status: error.response.status,
		};
	}
}

export async function obtenerAbogados() {
	try {
		const consulta = await axios.get(
			`${API_URL}/usuarios?filter=${encodeURI(JSON.stringify({ type: 'ABOGADO' }))}`,
			config
		);
		return consulta;
	} catch (error) {
		return error;
	}
}
