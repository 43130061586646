import CustomizedSnackbars from '../Alert&Toast/Alert';

const SUCCESS_STATUS = [200, 201, 700];
const ERROR_STATUS = [400, 401, 409, 404, 800];
const INFO_STATUS = [500, 501, 900];
const TIPOS_TOAST = { success: 'success', error: 'error', info: 'info', warning: 'warning' };

export function obtenerTipoToast(status) {
	if (status === 0) return 'no-render';
	if (SUCCESS_STATUS.includes(status)) return TIPOS_TOAST.success;
	if (ERROR_STATUS.includes(status)) return TIPOS_TOAST.error;
	if (INFO_STATUS.includes(status)) return TIPOS_TOAST.info;
	return TIPOS_TOAST.info;
}

export default function renderToast(status, mensaje) {
	const tipo = obtenerTipoToast(status);
	switch (tipo) {
		case 'no-render':
			return <></>;
		case 'success':
			return (
				<CustomizedSnackbars
					severity={'success'}
					mensaje={mensaje}
					style={{ position: 'absolute' }}
				/>
			);
		case 'error':
			return (
				<CustomizedSnackbars
					severity={'error'}
					mensaje={mensaje}
					style={{ position: 'absolute' }}
				/>
			);
		default:
			return (
				<CustomizedSnackbars
					progress={'progress'}
					severity={'info'}
					mensaje={mensaje}
					style={{ position: 'absolute', left: '50%' }}
				/>
			);
	}
}
