import React from 'react';
import { Box, Button, TextField } from '@mui/material';
import logo from '../../Assets/logoSPJ.jpg';
import { login } from '../../connections/conexionesUsuario';
// import Alert from '@mui/material/Alert';
import renderToast from '../../components/Utils/RenderToast';

export default function Ingresar() {
	const [responseStatus, setResponseStatus] = React.useState(0);

	const [usuario, setUsuario] = React.useState({
		username: '',
		password: '',
	});

	function manejarUsuario(e) {
		setUsuario({
			...usuario,
			[e.target.id]: e.target.value,
		});
	}

	// Se encarga de renderizar y desrenderizar el toast de la respuesta del servidor
	React.useEffect(() => {
		setTimeout(() => {
			setResponseStatus(0);
		}, 1000 * 1);
	}, [responseStatus]);

	async function enviarUsuario() {
		const response = await login(usuario);
		setResponseStatus(response.status);
		const statusLogueadoConExito = 200;


		if (response.status === statusLogueadoConExito) {
			localStorage.setItem('token', response.data.access_token);
			window.location.replace('/');
		}
	}


	window.addEventListener("keypress", function (e) {
		if (e.key === "Enter") {
			e.preventDefault()
			document.getElementById('boton-ingresar').click()
		}
	})

	// Ingreso con la tecla ENTER
	// const inputUsername = document.getElementById('username')
	// const inputPassword = document.getElementById('password')

	// if (inputUsername)
	// 	inputUsername.addEventListener("keypress", function (e) {
	// 		if (e.key === "Enter") {
	// 			e.preventDefault()
	// 			document.getElementById('boton-ingresar').click()
	// 		}
	// 	})

	// if (inputPassword)
	// 	inputPassword.addEventListener("keypress", function (e) {
	// 		if (e.key === "Enter") {
	// 			e.preventDefault()
	// 			document.getElementById('boton-ingresar').click()
	// 		}
	// 	})

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100vh',
				my: 'auto',
				width: '100%',
			}}
		>
			{renderToast(
				responseStatus,
				responseStatus === 200 ? 'Usuario logueado con éxito' : 'Revisa tus credenciales por favor'
			)}
			<Box
				sx={{
					display: 'flex',
					flexDirection: { xs: 'column', md: 'row' },
					justifyContent: 'center',
					alignItems: 'center',
					minWidth: 200,
					maxWidth: '800px',
					borderRadius: '8px',
					width: '100%',
					boxShadow: 3,
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						minWidth: 200,
						// maxWidth: '400px',
						width: { xs: '100%', sm: '100%', md: '50%' },
						height: '60vh',
						px: { xs: 1, md: 5 },
						pt: { xs: 3, md: 0 },
					}}
				>
					<TextField
						sx={{ my: 2, minWidth: 200, maxWidth: '400px', width: '80%' }}
						id='username'
						label='Nombre de usuario'
						variant='outlined'
						autoComplete='off'
						onChange={(e) => manejarUsuario(e)}
					/>
					<TextField
						type='password'
						sx={{ my: 2, minWidth: 200, maxWidth: '400px', width: '80%' }}
						id='password'
						label='Contraseña'
						variant='outlined'
						autoComplete='off'
						onChange={(e) => manejarUsuario(e)}
					/>
					<Button variant='contained' id='boton-ingresar' color='info' onClick={() => enviarUsuario()} >
						INGRESAR
					</Button>
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						width: { xs: '100%', sm: '100%', md: '50%' },
						minWidth: 200,
						// maxWidth: '400px',
						height: '60vh',
						borderRadius: { xs: '0', md: '0 8px 8px 0' },
						backgroundColor: 'rgb(33, 116, 186)',
						px: { xs: 1, md: 5 },
					}}
					style={{ overflow: 'hidden' }}
				>
					<img src={logo} alt='logoSPJ' style={{ objectFit: 'contain' }} width={'250px'} />
				</Box>
			</Box>
		</Box>
	);
}
