import React from 'react';
import { useNavigate } from 'react-router-dom';
import TablaPaginado from './TablaPaginado';
import Buscador from '../Buscador/Buscador';
import { TableSkeleton } from "../TableSkeleton/TableSkeleton";
import { obtenerCausas } from '../../connections/causas';
import { TablePagination, TableFooter, Box, Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NestedModalCausa from '../Alert&Toast/ModalEliminarCausa';
import { Link } from 'react-router-dom';
import './Tabla.css';

//  --------------- Recibe archivos del componente InicioAdmin ---------------
const Tabla = ({ archivos, admin, abogado }) => {
	const navigate = useNavigate();
	const [busqueda, setBusqueda] = React.useState('');
	const [render, setRender] = React.useState(0);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [causas, setCausas] = React.useState([]);
	const [totalCausas, setTotalCausas] = React.useState(0);
	const [filtros, setFiltros] = React.useState([
		`?limit=${rowsPerPage}`,
		'&',
		`offset=${page * rowsPerPage}`,
		'&',
		`order=asc`,
	]);

	const [loading, setLoading] = React.useState(true)

	React.useEffect(() => {
		setFiltros([
			`?limit=${rowsPerPage}`,
			'&',
			`offset=${page * rowsPerPage}`,
			'&',
			`order=asc`,
			'&',
			`filter=${encodeURI(JSON.stringify({ search: busqueda }))}`,
		]);
	}, [page, rowsPerPage, busqueda]);

	React.useEffect(() => {
		const filtrosQuery = filtros.join('');
		if (!causas.length || busqueda.length || !busqueda.length) {
			setLoading(true)
			async function cargarCausas() {
				const { data } = await obtenerCausas(filtrosQuery);
				setCausas(data.causas || [{ info: 'Not loaded' }]);
				setTotalCausas(data.total);
				setLoading(false)
			}
			cargarCausas();
		}
	}, [filtros, busqueda, causas.length]);

	/**
	 * This useEffect is used for update after a delete action
	 */
	React.useEffect(() => {
		// Should update table
		const filtrosQuery = filtros.join('');

		if (render !== 0) {
			setLoading(true)
			async function cargarCausas() {
				const { data } = await obtenerCausas(filtrosQuery);
				setCausas(data.causas || [{ info: 'Not loaded' }]);
				setTotalCausas(data.total);
				setLoading(false);
			}
			cargarCausas();
		}
	}, [render]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	function renderTable(causas) {
		return causas.map((causa, index) => (
			<TableRow key={index} className='tableRowDocCausa'>
				<TableCell align='left'><Link
					to={`/causa/${causa.id}`}
					style={{ textDecoration: 'none', color: 'black' }}
				>{causa.expediente || "Sin asignar"}</Link></TableCell>
				<TableCell align='left'>
					<Link
						to={`/causa/${causa.id}`}
						style={{ textDecoration: 'none', color: 'black' }}
					>
						{causa.caratula || 'Aún no asignado'}
					</Link>
				</TableCell>
				<TableCell align='left'><Link
					to={`/causa/${causa.id}`}
					style={{ textDecoration: 'none', color: 'black' }}
				>{causa.juzgado?.nombre}</Link></TableCell>
				<TableCell align='center'>
					<Box
						style={{
							height: '100%',
							width: '100%',
							position: 'relative',
						}}
					>
						<Link
							to={`/causa/${causa.id}`}
							style={{ textDecoration: 'none', color: 'white' }}
						>
							<NotificationsIcon color='info' />
							{causa.notificaciones > 0 && (
								<Box
									// className='puntoRojo'
									sx={{
										bgcolor: 'red',
										color: 'white',
										borderRadius: '50%',
										fontSize: '0.7rem',
										position: 'absolute',
										height: '1rem',
										width: '1rem',
										top: 15,
										left: '50%',
										zIndex: 'tooltip',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										// zIndex: 1,
									}}
								>
									{causa.notificaciones}
								</Box>
							)}
						</Link>
					</Box>
				</TableCell>
				{admin && (
					<TableCell align='center' sx={{ cursor: 'pointer' }}>
						<NestedModalCausa
							color='error'
							id={causa.id}
							setPage={setPage}
							render={render}
							setRender={setRender}
						/>
					</TableCell>
				)}
			</TableRow>
		))
	}

	return (
		<div>
			<Box className='botonNuevaDemanda'>
				<Buscador setBusqueda={setBusqueda} busqueda={busqueda} />
					{ abogado && (<Button variant='contained' color='error' onClick={() => navigate('/nuevademanda')}>
						Nueva Demanda
					</Button>
					)}
			</Box>
			<TableContainer component={Paper} sx={{ marginTop: '2vh' }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell align='left'>Nº de expediente</TableCell>
							<TableCell align='left'>Carátula</TableCell>
							<TableCell align='left'>Ubicación</TableCell>
							<TableCell align='center'> Notificación</TableCell>
							{admin && <TableCell align='center'>Eliminar</TableCell>}
						</TableRow>
					</TableHead>
					<TableBody>
					{loading ? (
              <TableSkeleton columnas={5} filas={rowsPerPage} />
            ) : (
							renderTable(causas)
            )}
						{/* {renderTable(causas)} */}
					</TableBody>
					<TableFooter className='tablePaginado'>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[5, 10, 20]}
								colSpan={5}
								count={totalCausas || 0}
								rowsPerPage={rowsPerPage}
								page={page}
								labelRowsPerPage='Demandas por página'
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								ActionsComponent={TablaPaginado}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</div>
	);
};

export default Tabla;
