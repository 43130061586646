import { createBrowserRouter } from 'react-router-dom';
import Causa from './routes/Causas/Causa';
import EditarArchivo from './components/Archivos/EditarArchivo';
import CargarNuevoArchivo from './routes/archivos/CargarNuevoArchivo';
import NuevaDemanda from './routes/abogado/nuevaDemanda/NuevaDemanda';
import CrearUsuario from './routes/admin/CrearUsuario';
import IngresarCausa from './routes/Causas/IngresarCausa';
import Ingresar from './routes/Login/Ingresar';
import Inicio from './routes/Inicio/Inicio';
import EditarUsuario from './routes/admin/EditarUsuario';
import ErrorPage from './routes/errorPage/errorPage';
import PaginaNoEncontrada from './routes/PaginaNoEncontrada/PaginaNoEncontrada';

const router = createBrowserRouter([
	{
		path: '/',
		element: <Inicio />,
		errorElement: <ErrorPage />,
	},
	{
		path: '/causa/:id',
		element: <Causa />,
		errorElement: <ErrorPage />,
	},
	{
		path: '/editarArchivo',
		element: <EditarArchivo />,
		errorElement: <ErrorPage />,
	},
	{
		path: '/editarUsuario/:id',
		element: <EditarUsuario />,
		errorElement: <ErrorPage />,
	},
	{
		path: '/causa/:id/nuevoarchivo',
		element: <CargarNuevoArchivo />,
		errorElement: <ErrorPage />,
	},
	{
		path: '/nuevademanda',
		element: <NuevaDemanda />,
		errorElement: <ErrorPage />,
	},
	{
		path: '/nuevousuario',
		element: <CrearUsuario />,
		errorElement: <ErrorPage />,
	},
	{
		path: '/ingresarcausa',
		element: <IngresarCausa />,
		errorElement: <ErrorPage />,
	},
	{
		path: '/ingresar',
		element: <Ingresar />,
		errorElement: <ErrorPage />,
	},
	{
		path: '*',
		element: <PaginaNoEncontrada />,
		errorElement: <ErrorPage />,
	},
]);

export default router;
