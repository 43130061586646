/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { paseContext } from '../Tablas/TablaPasesAbogados.jsx';
import { obtenerAbogados } from '../../connections/conexionesUsuario';
import {
	Box,
	Button,
	InputLabel,
	Select,
	MenuItem,
	FormControl,
	List,
	ListItem,
	IconButton,
	ListItemText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import '../../routes/Causas/Causa.css';
import { modificarAbogados } from '../../connections/causas.js';

export default function PopModificarAbogados({ id, abogados }) {
	const { setMostrarModificarAbogados } = useContext(paseContext);
	const [abogadosDisponibles, setAbogadosDisponibles] = React.useState([]);
	const [abogadosCausa, setAbogadosCausa] = React.useState([]);
	const [initial, setInitial] = React.useState([]);

	React.useEffect(() => {
		if (!initial.length) {
			async function cargarAbogados() {
				const consult = await obtenerAbogados();
				setAbogadosDisponibles(
					consult.data?.users || [{ error: 'Falta información de los abogados' }]
				);
				setInitial(consult.data?.users || [{ error: 'Falta información de los abogados' }]);
			}
			cargarAbogados();
		}
	});

	React.useEffect(() => {
		if (abogados && !abogadosCausa.length) {
			setAbogadosCausa(abogados);
		}
	}, [abogados]);

	React.useEffect(() => {
		if (abogadosCausa.length && initial.length) {
			const disponibles = obtenerDisponibles(abogadosCausa, initial);
			setAbogadosDisponibles(disponibles);
		}
	}, [initial, abogadosCausa]);

	function obtenerDisponibles(habilitados, todos) {
		let disponibles = todos;
		// const habs = [...habilitados];
		habilitados.forEach((hab) => {
			disponibles = disponibles.filter((abogado) => abogado.usuario !== hab.usuario);
		});
		return disponibles;
	}

	const ingresarAbogado = (e) => {
		e.preventDefault();
		return setAbogadosCausa([...abogadosCausa, e.target.value]);
	};

	const eliminarAbogadoSeleccionado = (abogado) => {
		const abogadoAEliminar = abogado;
		setAbogadosCausa(
			abogadosCausa.filter((abogado) => abogado.usuario !== abogadoAEliminar.usuario)
		);
	};

	return (
		<div className='contenedor'>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					my: 1,
					mx: 5,
					px: 5,
					minWidth: 200,
					maxWidth: '500px',
					width: '100%',
				}}
				className='contenedorPopUp'
			>
				<h2>Modificar Abogados</h2>
				<FormControl sx={{ my: 2, minWidth: 200 }}>
					<InputLabel>Abogados</InputLabel>
					<Select
						sx={{ minWidth: 200, maxWidth: '400px' }}
						labelId='demo-simple-select-helper-label'
						id='type'
						label='Abogados'
						value={''}
						onChange={(e) => {
							ingresarAbogado(e);
						}}
						required
					>
						<MenuItem value={''} key={9000 + 1}>
							{' '}
						</MenuItem>
						{abogadosDisponibles &&
							abogadosDisponibles.map((abogado, index) => {
								return (
									<MenuItem value={abogado} key={index + 1}>
										{abogado.usuario}
									</MenuItem>
								);
							})}
					</Select>
				</FormControl>

				<Box item xs={12} sx={{ my: 1, minWidth: 200, maxWidth: '400px' }}>
					<List>
						{abogadosCausa &&
							abogadosCausa.map((abogado, index) => (
								<ListItem
									key={index}
									secondaryAction={
										<IconButton
											edge='end'
											aria-label='delete'
											onClick={() => eliminarAbogadoSeleccionado(abogado)}
										>
											<DeleteIcon />
										</IconButton>
									}
								>
									<DeleteIcon
										value={abogado}
										onClick={() => {
											eliminarAbogadoSeleccionado(abogado);
										}}
									/>
									<ListItemText primary={abogado.usuario} />
								</ListItem>
							))}
					</List>
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-around',
						minWidth: 200,
						maxWidth: '600px',
						my: 4,
					}}
				>
					<Button
						variant='contained'
						color='info'
						onClick={() => {
							modificarAbogados(id, abogadosCausa);
							setMostrarModificarAbogados(false);
						}}
					>
						GUARDAR
					</Button>
					<Button
						variant='contained'
						color='error'
						onClick={() => setMostrarModificarAbogados(false)}
					>
						CANCELAR
					</Button>
				</Box>
			</Box>
		</div>
	);
}
