import jwt_decode from 'jwt-decode';

export default function decodeToken(token) {
	return jwt_decode(token);
}

export function obtenerTipoDeUsuario(token) {
	if (expiracionToken(token)) return cerrarSesion();
	return decodeToken(token).type;
}

export function restringirUsuarios(usuariosValidos) {
	const tipoDeUsuario = obtenerTipoDeUsuario(localStorage.getItem('token'));
	if (!usuariosValidos.includes(tipoDeUsuario)) return window.location.replace('/');
}

export function cerrarSesion() {
	localStorage.removeItem('token');
	return window.location.replace('/ingresar');
}

export function expiracionToken(token) {
	const { exp } = decodeToken(token);
	return exp < Date.now() / 1000;
}

export const tiposPosibles = {
	abogado: 'ABOGADO',
	juez: 'JUEZ',
	admin: 'ADMIN',
};
