import React from 'react';
import InicioAbogado from '../abogado/inicio/InicioAbogado';
import InicioAdmin from '../admin/InicioAdmin';
import InicioJuzgado from '../juzgado/InicioJuzgado';
import { obtenerTipoDeUsuario } from '../../connections/decodeToken';
import { tiposPosibles } from '../../connections/decodeToken';

const Inicio = () => {
	function mostrarInicioDeUsuario() {
		const token = localStorage.getItem('token');
		if (!token) return window.location.replace('/ingresar');
		const tipoDeUsuario = obtenerTipoDeUsuario(token);
		switch (tipoDeUsuario) {
			case tiposPosibles.abogado:
				return <InicioAbogado />;
			case tiposPosibles.juez:
				return <InicioJuzgado />;
			case tiposPosibles.admin:
				return <InicioAdmin />;

			default:
				return (
					<div>
						<h1>No se pudo obtener el tipo de usuario</h1>
					</div>
				);
		}
	}
	return <div>{mostrarInicioDeUsuario()}</div>;
};

export default Inicio;
