import React from 'react';
import { Box, Button, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ResponsiveAppBar from '../Nav';
import { useNavigate } from 'react-router-dom';

export default function EditarArchivo({ tipo, nombre, ruta }) {
	const navigate = useNavigate();
	const [archivo, setArchivo] = React.useState('');
	const [notificacion, setNotificacion] = React.useState('');
	const [rutaArchivo, setRutaArchivo] = React.useState('');

	function subirArchivo(e) {
		const widgetCloudinary = window.cloudinary.createUploadWidget(
			{
				cloudName: 'dn4r7ou4z',
				uploadPreset: 'ml_default',
			},
			(err, result) => {
				if (!err && result && result.event === 'success') {
					setRutaArchivo(result.info.url);
				}
			}
		);
		widgetCloudinary.open();
	}

	/**
	 * Esta función es la encargada de realizar la actualización del documento utilizando el estado de React
	 * @param {Evento} e recibe un evento que sucede en el botón de guardar actualización
	 * @return {void} void
	 */
	function manejarGuardar(e) {
		e.preventDefault();
		try {
			// Conectar con el backend y realizar el update
			// Dependiendo de la respuesta notificar al usuario si su actualización tuvo éxito o no
			if (true) alert('Se modificó con éxito');
		} catch (error) {
			// Alertar de un error al usuario
		}
	}

	/**
	 * Esta función es la encargada de realizar la eliminación del documento
	 * @param {Evento} e recibe un evento que sucede en el botón de eliminar documento
	 * @return {void} void
	 */
	function manejarEliminar(e) {
		e.preventDefault();
		try {
			// Conectar con el backend y realizar el update
			// Dependiendo de la respuesta notificar al usuario si su actualización tuvo éxito o no
			if (true) alert('Se eliminó con éxito');
		} catch (error) {
			// Alertar de un error al usuario
		}
	}

	const manejadorDeArchivo = (event) => {
		setArchivo(event.target.value);
	};

	function manejadorDeNotificacion(e) {
		e.preventDefault();
		setNotificacion(e.target.value);
	}

	return (
		<div>
			<ResponsiveAppBar />
			<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 5 }}>
				<Button onClick={() => navigate(-1)}>
					<ArrowBackIcon />
				</Button>
				<h2>Editar archivo</h2>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					m: 1,
					minWidth: 200,
					maxWidth: '400px',
					width: '100%',
					// Esto lo puse de prueba para que no me quede pegado a la izquierda
					ml: 'auto',
					mr: 'auto',
				}}
			>
				{/* <Box sx={{ m: 1, minWidth: 200, maxWidth: '600px', width: '100%' }}> */}
				<InputLabel id='demo-simple-select-helper-label'>Seleccionar tipo de Archivo</InputLabel>
				<Select
					sx={{ my: 2, minWidth: 200, maxWidth: '400px' }}
					labelId='demo-simple-select-helper-label'
					id='demo-simple-select-helper'
					value={archivo}
					// label='demo-simple-select-helper-label'
					onChange={(e) => manejadorDeArchivo(e)}
					required
				>
					<MenuItem value={'actuaciones'}>Actuaciones</MenuItem>
					<MenuItem value={'pruebas'}>Prueba</MenuItem>
					<MenuItem value={'notificaciones'}>Notificaciones</MenuItem>
					<MenuItem sx={{ fontWeight: 'bold' }} value={'borradores'}>
						Borrador
					</MenuItem>
				</Select>

				<br />

				{archivo === 'notificaciones' && (
					<Box>
						<InputLabel id='select-helper'>Seleccionar tipo de Notificación</InputLabel>
						<Select
							sx={{ my: 2, minWidth: 200, maxWidth: '400px', width: '100%' }}
							labelId='select-helper'
							id='select-helper'
							value={notificacion}
							onChange={(e) => manejadorDeNotificacion(e)}
							required
						>
							<MenuItem value={'demanda'}>Notifica demanda</MenuItem>
							<MenuItem value={'reconvención'}>Notifica reconvención</MenuItem>
							<MenuItem value={'excepciones'}>Notifica las excepciones interpuestas</MenuItem>
							<MenuItem value={'providencia'}>Notifica providencia</MenuItem>
							<MenuItem value={'interlocutoria'}>Notifica interlocutoria</MenuItem>
							<MenuItem value={'definitiva'}>Notifica definitiva</MenuItem>
							<MenuItem value={'intimación'}>Notifica intimación</MenuItem>
						</Select>
					</Box>
				)}
				<TextField
					sx={{ my: 2, minWidth: 200, maxWidth: '400px' }}
					id='nombre'
					label='Nombrar Archivo'
					variant='outlined'
					autoComplete='off'
				/>
				<Box sx={{ my: 8, minWidth: 200, maxWidth: '600px', height: 120 }}>
					<Button
						variant='outlined'
						sx={{
							minWidth: 180,
							width: '100%',
							height: 100,
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
							border: 'dotted',
							overflow: 'hidden',
							marginTop: '-50px',
						}}
						onClick={(e) => subirArchivo(e)}
					>
						<UploadFileIcon
							style={{
								color: 'info',
								fontSize: '40px',
							}}
						/>
						{rutaArchivo && <h2>{rutaArchivo}</h2>}
					</Button>
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-around',
						marginTop: '-100px'
					}}
				>
					<Button variant='contained' color='info' onClick={(e) => manejarGuardar(e)}>
						GUARDAR
					</Button>
					<Button variant='contained' color='error' onClick={(e) => manejarEliminar(e)}>
						ELIMINAR
					</Button>
				</Box>
				{/* </Box> */}
			</Box>
		</div>
	);
}
