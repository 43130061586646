import React from 'react';
import { Box, Button, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ResponsiveAppBar from '../../components/Nav';
import { useNavigate, useParams } from 'react-router-dom';
import RenderToast from '../../components/Utils/RenderToast';
import { nuevoDocumento } from '../../connections/causas.js';
import './CargarNuevoArchivo.css';

export default function CargarNuevoArchivo() {
	const { id } = useParams();
	const navigate = useNavigate();
	const [documento, setDocumento] = React.useState({
		nombre: '',
		type: '',
		notificationType: '',
		id_causa: id,
		file: undefined,
	});
	const [status, setStatus] = React.useState(0);
	const [errors, setErrors] = React.useState({});

	window.onbeforeunload = prevenirSalir;

	function prevenirSalir() {
		if (status === 501) {
			return 'Espera a que el archivo termine de cargar antes de salir';
		}
	}

	React.useEffect(() => {
		if (status === 201) {
			setTimeout(() => {
				return navigate(-1);
			}, 1000 * 1);
		}
	}, [status, navigate]);

	/**
	 * Esta función es la encargada de realizar la actualización del documento utilizando el estado de React
	 * @param {Evento} e recibe un evento que sucede en el botón de guardar actualización
	 * @return {void} void
	 */
	async function manejarGuardar(e) {
		e.preventDefault();
		try {
			if (Object.keys(errors).length) {
				setStatus(800);
				setTimeout(() => {
					setStatus(0);
				}, 1000 * 1);
				return;
			}
			setStatus(501);
			const consult = await nuevoDocumento(documento);
			setStatus(consult.status);
			setTimeout(() => {
				setStatus(0);
			}, 1000 * 1);
		} catch (error) {
			setStatus(500);
			setTimeout(() => {
				setStatus(0);
			}, 1000 * 2);
		}
	}

	const manejarArchivo = (e) => {
		e.preventDefault();
		setDocumento({
			...documento,
			file: e.target.files[0],
		});
	};

	const manejadorDeCambios = (e) => {
		e.preventDefault();
		if (e.target.name !== undefined)
			setDocumento({ ...documento, [e.target.name]: e.target.value });
		setErrors(
			validate({
				...documento,
				[e.target.name]: e.target.value,
			})
		);
	};

	function getMsg() {
		switch (status) {
			case 201:
				return '¡Nuevo archivo cargado con éxito!';
			case 500:
				return 'Error interno del servidor, por favor intentelo más tarde';
			case 501:
				return 'Cargando los archivos, espera por favor. No salgas de la página';
			case 800:
				return 'Debes completar todos los campos requeridos';
			default:
				break;
		}
	}
	function validate(input) {
		const errors = {};
		if (!input.nombre) {
			errors.nombre = 'Debe ingresar un Nombre de Archivo.';
		}
		if (!input.type) {
			errors.type = 'Debe seleccionar un Tipo de Archivo.';
		}
		// if (input.id_causa && !/[A-Za-z0-9]{6,12}/.test(input.id_causa)) {
		// 	errors.notificationType = 'La contraseñá debe contener entre 6 y 12 caracteres sin espacios.';
		// }
		// if (input.file !== input.file) {
		// 	errors.password2 = 'La contraseña no coincide.';
		// }
		return errors;
	}

	return (
		<div>
			{RenderToast(status, getMsg())}
			<ResponsiveAppBar />
			<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 5 }}>
				<Button onClick={() => navigate(-1)}>
					<ArrowBackIcon />
				</Button>
				<h2>Cargar nuevo archivo</h2>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					m: 1,
					minWidth: 200,
					maxWidth: '400px',
					width: '100%',
					ml: 'auto',
					mr: 'auto',
				}}
			>
				<InputLabel id='demo-simple-select-helper-label' required>
					Seleccionar tipo de Archivo
				</InputLabel>
				<Select
					sx={{ my: 2, minWidth: 200, maxWidth: '400px' }}
					labelId='demo-simple-select-helper-label'
					id='demo-simple-select-helper'
					value={documento.type}
					onChange={(e) => manejadorDeCambios(e)}
					required
					name='type'
				>
					<MenuItem value={'ACTUACION'}>Actuaciones</MenuItem>
					<MenuItem value={'PRUEBA'}>Prueba</MenuItem>
					<MenuItem value={'NOTIFICACION'}>Notificaciones</MenuItem>
					<MenuItem sx={{ fontWeight: 'bold' }} value={'BORRADOR'}>
						Borrador
					</MenuItem>
				</Select>
				<p style={{ color: 'red', marginTop: '-16px', height: '20px' }}>
					{errors.type ? errors.type : ''}
				</p>

				<br />

				{documento.type === 'NOTIFICACION' && (
					<Box>
						<InputLabel id='select-helper'>Seleccionar tipo de Notificación</InputLabel>
						<Select
							sx={{ my: 2, minWidth: 200, maxWidth: '400px', width: '100%' }}
							labelId='select-helper'
							id='select-helper'
							value={documento.notificationType}
							name='notificationType'
							onChange={(e) => manejadorDeCambios(e)}
							required
						>
							<MenuItem value={'DEMANDA'}>Notifica demanda</MenuItem>
							<MenuItem value={'RECONVENCION'}>Notifica reconvención</MenuItem>
							<MenuItem value={'EXCEPCIONES'}>Notifica las excepciones interpuestas</MenuItem>
							<MenuItem value={'INTIMACION'}>Notifica intimación</MenuItem>
							<MenuItem value={'PROVIDENCIA'}>Notifica providencia</MenuItem>
							<MenuItem value={'SENTENCIA_INTERLOCUTORIA'}>
								Notifica sentencia interlocutoria
							</MenuItem>
							<MenuItem value={'SENTENCIA_DEFINITIVA'}>Notifica sentencia definitiva</MenuItem>
						</Select>
					</Box>
				)}

				<TextField
					sx={{ my: 2, minWidth: 200, maxWidth: '400px' }}
					id='nombre'
					label='Nombrar Archivo'
					variant='outlined'
					autoComplete='off'
					name='nombre'
					required
					onChange={(e) => manejadorDeCambios(e)}
				/>
				<p style={{ color: 'red', marginTop: '-16px', height: '20px' }}>
					{errors.nombre ? errors.nombre : ''}
				</p>
				<Box
					sx={{ my: 8, minWidth: 200, maxWidth: '400px', height: 50 }}
					className='inputBoxNuevoArchivo'
				>
					<input
						type='file'
						name='file'
						id='outlined-basic'
						sx={{
							width: '100%',
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
						}}
						onChange={(e) => manejarArchivo(e)}
					/>
				</Box>
				{documento.file && (
					<h5 style={{ textAlign: 'center', width: '100%', margin: '0 10px' }}>
						El archivo {documento.file.name} se cargó correctamente.
					</h5>
				)}
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-around',
						marginTop: '5vh',
						cursor: 'pointer',
					}}
				>
					<Button variant='contained' color='info' onClick={(e) => manejarGuardar(e)}>
						GUARDAR
					</Button>
					<Button variant='contained' color='error' onClick={() => navigate(-1)}>
						CANCELAR
					</Button>
				</Box>
			</Box>
		</div>
	);
}
