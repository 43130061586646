import React from "react";
import { TableRow, TableCell, Skeleton } from "@mui/material";

export const TableSkeleton = ({ columnas, filas }) => {
  return Array.from(Array(filas).keys()).map((index) => (
    <TableRow key={index}>
      <TableCell colSpan={columnas}>
        <Skeleton height={20} variant="rectangular" />
      </TableCell>
    </TableRow>
  ));
};
