const TERMS_TIME = {
	DEMANDA: 15,
	RECONVENCION: 10,
	EXCEPCIONES: 10,
	INTIMACION: 10,
	PROVIDENCIA: 5,
	SENTENCIA_INTERLOCUTORIA: 5,
	SENTENCIA_DEFINITIVA: 10,
};
const ONE_DAY = 1000 * 60 * 60 * 24;

export const gestDate = (dateString) => {
	let date = Date.parse(dateString);
	date = new Date(date);
	const options = { day: 'numeric', month: 'long', year: 'numeric' };
	return date.toLocaleDateString('es-ES', options);
};

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export function formatDate(date) {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
    ].join(':')
  );
}

export function getDate(dateString){
	let date = Date.parse(dateString);
	date = new Date(date);
	return formatDate(date)
}

/**
 * Esta función retorna el número de días que faltan para que finalice el plazo de una notificación
 * @param Date Fecha de creación del documento
 * @param String Tipo de notificación
 */
export const getTerm = (dateString, type) => {
	const date = Date.parse(dateString);
	const end = date + ONE_DAY * TERMS_TIME[type];
	const actual = Date.now();
	return Math.round((end - actual) / ONE_DAY);
};
