import React from 'react';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Box,
	Button,
} from '@mui/material';

import PopNuevoPase from '../../components/PopUpsTablaPases/PopNuevoPase';
import PopModificarAbogados from '../PopUpsTablaPases/PopModificarAbogados';
import * as dateParser from '../Utils/DateParser.js';
import { obtenerTipoDeUsuario, tiposPosibles } from '../../connections/decodeToken';
import { TableSkeleton } from '../TableSkeleton/TableSkeleton';

export const paseContext = React.createContext();

function TablaPasesAbogados({ tipo, abogados, pases, id, idCausa, loading, render, setRender }) {
	const [mostrarNuevoPase, setMostrarNuevoPase] = React.useState(false);
	const [mostrarModificarAbogados, setMostrarModificarAbogados] = React.useState(false);

	let data = [];
	abogados ? (data = abogados) : (data = pases);

	// --------------- Componente ---------------
	return (
		<Box>
			{mostrarNuevoPase && (
				<paseContext.Provider value={{ setMostrarNuevoPase }}>
					<PopNuevoPase id={idCausa} render={render} setRender={setRender} />
				</paseContext.Provider>
			)}
			{mostrarModificarAbogados && (
				<paseContext.Provider value={{ setMostrarModificarAbogados }}>
					<PopModificarAbogados id={idCausa} abogados={abogados} />
				</paseContext.Provider>
			)}
			<Box className='botonNuevaDemanda' sx={{ display: 'flex', justifyContent: 'flex-end' }}>
				{tipo === '5' && obtenerTipoDeUsuario(localStorage.getItem('token')) === tiposPosibles.juez && (
					<Button
						variant='contained'
						color='info'
						onClick={() => {
							setMostrarNuevoPase(true);
						}}
					>
						Nuevo Pase
					</Button>
				)}
				{tipo === '6' &&
					obtenerTipoDeUsuario(localStorage.getItem('token')) === tiposPosibles.juez && (
						<Button
							variant='contained'
							color='info'
							onClick={() => {
								setMostrarModificarAbogados(true);
							}}
						>
							Modificar
						</Button>
					)}
			</Box>
			<TableContainer component={Paper}>
				<Table>
					<TableHead>
						<TableRow>
							{tipo === '5' && <TableCell align='left'>Fecha de Pase</TableCell>}
							{tipo === '5' && <TableCell align='left'>Origen</TableCell>}
							{tipo === '5' && <TableCell align='left'>Destino</TableCell>}
							{tipo === '6' && <TableCell align='left'>Habilitados</TableCell>}
						</TableRow>
					</TableHead>
					<TableBody>
						{loading ? (<TableSkeleton columnas={4} filas={5} />) :
							(data &&
								data.map((elem, index) => (
									<TableRow key={`${index}${Math.floor(Math.random() * 100)}`} className='tableRowDocCausa'>
										{tipo === '5' && (
											<TableCell align='left'>{dateParser.getDate(elem.created_at)}</TableCell>
										)}
										{tipo === '5' && <TableCell align='left'>{elem.juzgado_origen.nombre}</TableCell>}
										{tipo === '5' && (
											<TableCell align='left'>{elem.juzgado_destino.nombre}</TableCell>
										)}
										{tipo === '6' && <TableCell align='left'>{elem.usuario}</TableCell>}
									</TableRow>
								)))
						}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
}

export default TablaPasesAbogados;
