import React, { useContext } from 'react';
import axios from 'axios';
import { paseContext } from '../Tablas/TablaPasesAbogados.jsx';
import { nuevoPase } from '../../connections/causas.js';
import renderToast from '../../components/Utils/RenderToast';
// import { red } from '@mui/material/colors';
import { Box, Button, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import '../../routes/Causas/Causa.css';

// const color = red[500];

const API_URL = process.env.REACT_APP_SPJ_API;

export default function PopNuevoPase({ id, render, setRender }) {
	const { setMostrarNuevoPase } = useContext(paseContext);
	const [posiblesDestinos, setPosiblesDestinos] = React.useState([]);
	const [nuevoDestino, setNuevoDestino] = React.useState('');
	const [status, setStatus] = React.useState(0);

	React.useEffect(() => {
		async function obtenerJuzgados() {
			const { data } = await axios.get(`${API_URL}/juzgados`);
			setPosiblesDestinos(data.juzgados);
		}
		obtenerJuzgados();
	}, []);

	React.useEffect(() => {
		if (status === 201) {
			setTimeout(() => {
				setMostrarNuevoPase(false);
			}, 1000 * 1);
		}
	}, [status, setMostrarNuevoPase]);

	async function enviarPase(e) {
		e.preventDefault();
		const consult = await nuevoPase(id, nuevoDestino);

		if (consult?.status === 201) {
			setRender(render + 1)
		}

		setStatus(consult?.status || 400);
		setTimeout(() => {
			if (status === 201) {
				setMostrarNuevoPase(false)
			};
			setStatus(0);
		}, 1000 * 1);
	}

	function mensajeToast() {
		switch (status) {
			case 201:
				return 'Pase realizado con éxito';
			case 401:
				return 'No tienes permisos para realizar esta acción';
			case 400:
				return 'Algún parámetro enviado es inválido';
			case 404:
				return 'La causa solicitada ya no existe';
			default:
				return 'Error interno del servidor, estamos trabajando para solucionarlo';
		}
	}

	return (
		<div className='contenedor'>
			{renderToast(status, mensajeToast())}
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					my: 1,
					mx: 5,
					px: 5,
					minWidth: 200,
					maxWidth: '600px',
					width: '100%',
				}}
				className='contenedorPopUp'
			>
				<h2>Iniciar Pase</h2>
				<FormControl sx={{ my: 2, minWidth: 200 }}>
					<InputLabel>Pase</InputLabel>
					<Select
						sx={{ minWidth: 200, maxWidth: '400px' }}
						labelId='demo-simple-select-helper-label'
						id='type'
						label='Pase'
						value={nuevoDestino}
						onChange={(e) => {
							setNuevoDestino(e.target.value);
						}}
						required
					>
						{posiblesDestinos.length
							? posiblesDestinos.map((destino, index) => {
								return (
									<MenuItem value={destino.id} key={index}>
										{destino.nombre}
									</MenuItem>
								);
							})
							: ''}
					</Select>
				</FormControl>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-around',
						minWidth: 200,
						maxWidth: '600px',
						my: 4,
					}}
				>
					<Button variant='contained' color='info' onClick={enviarPase}>
						GUARDAR
					</Button>
					<Button variant='contained' color='error' onClick={() => setMostrarNuevoPase(false)}>
						CANCELAR
					</Button>
				</Box>
			</Box>
		</div>
	);
}
