import React from 'react';
import { Box, Button, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import { restringirUsuarios, tiposPosibles } from '../../connections/decodeToken';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import ResponsiveAppBar from '../../components/Nav';
import { crearUsuario } from '../../connections/conexionesUsuario';
import renderToast from '../../components/Utils/RenderToast';

function validate(input) {
	const errors = {};
	if (!input.mail) {
		errors.mail = 'Este campo es requerido.';
	} else if (input.mail && !/\S+@\S+\.+\S+/.test(input.mail)) {
		errors.mail = 'Debe ser un correo válido.';
	}
	if (!input.usuario) {
		errors.usuario = 'Este campo es requerido.';
	} else if (input.usuario && !/[A-Za-z0-9]{8,20}/.test(input.usuario)) {
		errors.usuario = 'El nombre de usuario debe contener entre 8 y 20 caracteres sin espacios.';
	}
	if (!input.password) {
		errors.password = 'Este campo es requerido.';
	} else if (input.password && !/[A-Za-z0-9]{6,12}/.test(input.password)) {
		errors.password = 'La contraseñá debe contener entre 6 y 12 caracteres sin espacios.';
	}
	if (input.password2 !== input.password) {
		errors.password2 = 'La contraseña no coincide.';
	}
	return errors;
}

const CrearUsuario = () => {
	restringirUsuarios(tiposPosibles.admin);

	const navigate = useNavigate();

	const [errors, setErrors] = React.useState({});
	const [status, setStatus] = React.useState(0);

	const [nuevoUsuario, setNuevoUsuario] = React.useState({
		usuario: '',
		mail: '',
		password: '',
		password2: '',
		type: 'ABOGADO',
	});

	React.useEffect(() => {
		if (status === 201) {
			setTimeout(() => {
				window.location.replace('/');
			}, 1000 * 1);
		}
		if (status !== 0)
			setTimeout(() => {
				setStatus(0);
				// 200 -> instant
			}, 1000 * 1);
	}, [status]);

	const FUNCIONES = ['ABOGADO', 'JUEZ', 'ADMIN'];

	const manejadorDeUsuario = (event) => {
		if (event.target.id === undefined)
			return setNuevoUsuario({
				...nuevoUsuario,
				type: event.target.value,
			});
		setNuevoUsuario({
			...nuevoUsuario,
			[event.target.id]: event.target.value,
		});
		setErrors(
			validate({
				...nuevoUsuario,
				[event.target.id]: event.target.value,
			})
		);
	};

	async function enviarUsuario(e) {
		e.preventDefault();
		if (Object.keys(errors).length || !FUNCIONES.includes(nuevoUsuario.type)) {
			return setStatus(800);
		}

		const response = await crearUsuario({
			usuario: nuevoUsuario.usuario,
			type: nuevoUsuario.type,
			mail: nuevoUsuario.mail,
			password: nuevoUsuario.password,
		});
		return setStatus(response.status);
	}

	function mensajeToast() {
		switch (status) {
			case 201:
				return 'Usuario creado con éxito';
			case 400:
				return 'Envió un parametro inválido';
			case 409:
				return 'Usuario ya existe';
			case 800:
				return 'Revisa la información, faltan campos requeridos';
			default:
				return 'Error interno del servidor, estamos trabajando para solucionarlo';
		}
	}

	return (
		<div>
			{renderToast(status, mensajeToast())}
			<ResponsiveAppBar />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					mb: 5,
				}}
			>
				<Button onClick={() => navigate(-1)}>
					<ArrowBackIcon />
				</Button>
				<h2>Crear nuevo usuario</h2>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					m: 1,
					minWidth: 200,
					maxWidth: '400px',
					width: '100%',
					// Esto lo puse de prueba para que no me quede pegado a la izquierda
					ml: 'auto',
					mr: 'auto',
				}}
			>
				<TextField
					sx={{ my: 2, minWidth: 200, maxWidth: '400px' }}
					id='mail'
					name='mail'
					required
					label='Correo Electrónico'
					variant='outlined'
					autoComplete='off'
					onChange={(e) => manejadorDeUsuario(e)}
				/>
				<p style={{ color: 'red', marginTop: '-16px', height: '20px' }}>
					{errors.mail ? errors.mail : ''}
				</p>
				<TextField
					sx={{ my: 2, minWidth: 200, maxWidth: '400px' }}
					id='usuario'
					name='usuario'
					required
					label='Nombre de Usuario'
					variant='outlined'
					autoComplete='off'
					onChange={(e) => manejadorDeUsuario(e)}
				/>
				<p style={{ color: 'red', marginTop: '-16px', height: '20px' }}>
					{errors.usuario ? errors.usuario : ''}
				</p>
				<TextField
					type='password'
					sx={{ my: 2, minWidth: 200, maxWidth: '400px' }}
					id='password'
					name='password'
					required
					label='Contraseña'
					variant='outlined'
					autoComplete='off'
					onChange={(e) => manejadorDeUsuario(e)}
				/>
				<p style={{ color: 'red', marginTop: '-16px', height: '20px' }}>
					{errors.password ? errors.password : ''}
				</p>
				<TextField
					type='password'
					sx={{ my: 2, minWidth: 200, maxWidth: '400px' }}
					id='password2'
					name='password2'
					required
					label='Reingresa la Contraseña'
					variant='outlined'
					autoComplete='off'
					onChange={(e) => manejadorDeUsuario(e)}
				/>
				<p style={{ color: 'red', marginTop: '-16px', height: '20px' }}>
					{errors.password2 ? errors.password2 : ''}
				</p>
				<br />
				<InputLabel id='demo-simple-select-helper-label'>Selecciona el rol del Usuario</InputLabel>
				<Select
					sx={{ my: 2, minWidth: 200, maxWidth: '400px' }}
					labelId='demo-simple-select-helper-label'
					id='type'
					value={nuevoUsuario.type}
					onChange={(e) => manejadorDeUsuario(e)}
					required
				>
					{FUNCIONES &&
						FUNCIONES.map((funcion, index) => {
							return (
								<MenuItem value={funcion} key={index}>
									{funcion}
								</MenuItem>
							);
						})}
				</Select>
				<br />
				<Box
					sx={{
						minWidth: 200,
						maxWidth: '400px',
						display: 'flex',
						justifyContent: 'space-around',
					}}
				>
					<Button
						// disabled={Object.keys(errors).length ? 'true' : 'false'}
						variant='contained'
						color='info'
						onClick={(e) => enviarUsuario(e)}
					>
						GUARDAR
					</Button>
					<Button variant='contained' color='error' onClick={() => navigate(-1)}>
						CANCELAR
					</Button>
				</Box>
			</Box>
		</div>
	);
};

export default CrearUsuario;
