import React from 'react';
import Nav from '../../../components/Nav.jsx';
import TablaAbogado from '../../../components/Tablas/TablaCausas.jsx';
import Footer from '../../../components/Footer/Footer.jsx';
import { restringirUsuarios, tiposPosibles } from '../../../connections/decodeToken.js';
import { Box } from '@mui/material';
import './InicioAbogado.css';

const InicioAbogado = () => {
	restringirUsuarios([tiposPosibles.abogado]);

	return (
		<Box>
			<Nav />
			<Box className='contenedorTabla'>
				<h2 className='titulo'>Causas</h2>
				<TablaAbogado abogado={'abogado'} />
			</Box>
			<Footer />
		</Box>
	);
};

export default InicioAbogado;
