import axios from 'axios';

const API_URL = process.env.REACT_APP_SPJ_API;

export async function obtenerJuzgados() {
	try {
		const consult = await axios.get(`${API_URL}/juzgados`);
		return consult;
	} catch (error) {
		return error;
	}
}
