import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import './Nav.css';
import logo from '../Assets/logoSPJ.jpg';
import decodeToken, { cerrarSesion } from '../connections/decodeToken';

function ResponsiveAppBar() {
	const [anchorElUser, setAnchorElUser] = React.useState(null);

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const obtenerPrimeraLetra = () => {
		const token = localStorage.getItem('token');
		if (!token) return 'S';
		const info = decodeToken(token);
		return info.usuario[0].toUpperCase();
	};
	const obtenerNombreUsuario = () => {
		const token = localStorage.getItem('token');
		if (!token) return 'S';
		const info = decodeToken(token);
		return info.usuario.toUpperCase();
	};

	return (
		<AppBar position='static' className='containerNav2'>
			<Toolbar className='containerNav' disableGutters>
				<img src={logo} alt='logoSPJ' width={'50px'} className='logoo' />
				<Typography
					variant='h6'
					noWrap
					component='a'
					href='/'
					sx={{
						mr: 2,
						ml: 2,
						display: { xs: 'none', md: 'flex' },
						fontFamily: 'Roboto',
						fontWeight: 300,
						letterSpacing: '.5rem',
						color: 'inherit',
						textDecoration: 'none',
						fontSize: '1rem',
						float: 'left',
					}}
				>
					Simulador Poder Judicial
				</Typography>

				<Box
					sx={{
						flexGrow: 1,
						display: { xs: 'none', md: 'flex' },
					}}
				></Box>

				<Box sx={{ flexGrow: 0 }}>
					<Tooltip>
						<IconButton
							className='circuloUsuario'
							onClick={handleOpenUserMenu}
							sx={{
								p: 0,
								backgroundColor: 'white',
								color: 'gray',
								width: '45px',
								height: '45px',
								borderRadius: '50%',
							}}
						>
							<h3>{obtenerPrimeraLetra()}</h3>
						</IconButton>
					</Tooltip>

					<Menu
						sx={{ mt: '45px' }}
						id='menu-appbar'
						anchorEl={anchorElUser}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={Boolean(anchorElUser)}
						onClose={handleCloseUserMenu}
					>
						<Typography textAlign='center' variant='body2' className='typographyNav'>
							{obtenerNombreUsuario()}
						</Typography>
						<MenuItem onClick={handleCloseUserMenu}>
							<Typography textAlign='center' onClick={(e) => cerrarSesion(e)}>
								Cerrar sesión
							</Typography>
						</MenuItem>
					</Menu>
				</Box>
			</Toolbar>
		</AppBar>
	);
}
export default ResponsiveAppBar;
