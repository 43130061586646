import React from 'react';
import { Box, Container, Typography, Link } from '@mui/material';
import logo from '../../Assets/logoSPJ.jpg';
import './Footer.css';

function Copyright() {
	return (
		<Typography variant='body2' color='text.secondary'>
			{'Copyright © '}
			{/* <Link color='inherit' href='https://mui.com/'>
				Your Website
			</Link>{' '} */}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

function Footer() {
	return (
		<Box
			className='containerFooter'
			component='footer'
			boxShadow={2}
			sx={{
				py: 2,
				px: 2,
				display: 'flex',
				mt: 5,
				backgroundColor: (theme) =>
					theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800],
			}}
		>
			<Container maxWidth='sm' className='contenedorImagen'>
				<Typography variant='body1'>Simulador de Poder Judicial.</Typography>
				<Copyright />
				<img src={logo} alt='logo' />
			</Container>

			<Container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
				<Box>
					<Typography variant='body1'>Equipo de Desarrollo</Typography>
					<Typography variant='body2' color='text.secondary'>
						Marco Gini
					</Typography>
					<Typography variant='body2' color='text.secondary'>
						Matías Straface
					</Typography>
					<Typography variant='body2' color='text.secondary'>
						Melissa Hellrigl
					</Typography>
					<Typography variant='body2' color='text.secondary'>
						Sebastian Pérez
					</Typography>
					<Typography variant='body2' color='text.secondary'>
						Valentin Schiaffino
					</Typography>
				</Box>
				<Box sx={{ ml: 4 }} >
					<Typography variant='body1'>Participantes</Typography>
					<Typography variant='body2' color='text.secondary'>
						Matías Varas
					</Typography>
					<Typography variant='body2' color='text.secondary'>
						Tomás Mudano
					</Typography>
				</Box>
			</Container>
		</Box>
	);
}

export default Footer;
