import React from "react";
import {
  TablePagination,
  TableFooter,
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import TablaPaginado from "../../components/Tablas/TablaPaginado";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import "../../components/Tablas/Tabla.css";
import IngresarCausa from "../Causas/IngresarCausa";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { obtenerCausasJuez } from "../../connections/causas";
import Buscador from "../../components/Buscador/Buscador.jsx";
import { Link } from "react-router-dom";
import "../abogado/inicio/InicioAbogado.css";
import { TableSkeleton } from "../../components/TableSkeleton/TableSkeleton.jsx";

export const causaContext = React.createContext();

//  --------------- Recibe archivos del componente InicioJuzgado ---------------
const TablaJuzgado = ({ tipo }) => {
  const [busqueda, setBusqueda] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [mostrarIngresarCausa, setMostrarIngresarCausa] = React.useState(false);
  const [causas, setCausas] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [filters, setFilters] = React.useState(["sort=id"]);
  const [causaAIngresar, setCausaAIngresar] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  function getTipo() {
    if (!tipo) return "recibidos";
    if (tipo === "1") return "recibidos";
    else return "a recibir";
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  React.useEffect(() => {
    setFilters([`limit=${rowsPerPage}`, `offset=${page * rowsPerPage}`]);
  }, [page, rowsPerPage]);

  React.useEffect(() => {
    if (getTipo() === "recibidos") {
      setLoading(true);
      obtenerCausasJuez(
        `${filters.join("&")}&search=${JSON.stringify("")}&filter=${encodeURI(
          JSON.stringify({ recibidas: true, search: busqueda })
        )}`
      ).then(({ data }) => {
        setCausas(data.causas);
        setTotal(data.total);
        setLoading(false);
      });
    } else {
      setLoading(true);
      obtenerCausasJuez(
        `${filters.join("&")}&filter=${encodeURI(
          JSON.stringify({ recibidas: false, search: busqueda })
        )}`
      ).then(({ data }) => {
        setCausas(data.causas);
        setTotal(data.total);
        setLoading(false);
      });
    }
  }, [page, rowsPerPage, filters, busqueda]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function renderTable(causas) {
    if (!causas || !causas.length) {
      return (
        <TableRow>
          <TableCell>
            <h4>No hay Causas para mostrar</h4>
          </TableCell>
        </TableRow>
      );
    }
    return causas.map((causa, index) => {
      return (
        <TableRow key={index} className="tableJuzgado">
          <TableCell align="left">
            <Link
              to={`/causa/${causa.id}`}
              style={{
                textDecoration: "none",
                color: "black",
                cursor: "pointer",
              }}
            >
              {causa.expediente || "Sin asignar"}
            </Link>
          </TableCell>
          <TableCell align="left">
            <Link
              to={`/causa/${causa.id}`}
              style={{
                textDecoration: "none",
                color: "black",
                cursor: "pointer",
              }}
            >
              {causa.caratula || "Aún sin asignar"}
            </Link>
          </TableCell>

          <TableCell align="left">
            <Link
              to={`/causa/${causa.id}`}
              style={{
                textDecoration: "none",
                color: "black",
                cursor: "pointer",
              }}
            >
              {" "}
              {causa.juzgado.nombre}
            </Link>
          </TableCell>

          <TableCell align="center">
            <Box
              style={{
                height: "100%",
                width: "100%",
                position: "relative",
              }}
            >
              <Link
                to={`/causa/${causa.id}`}
                style={{
                  textDecoration: "none",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                <NotificationsIcon color="info" />
                {causa.notificaciones > 0 && (
                  <Box
                    sx={{
                      bgcolor: "red",
                      color: "white",
                      borderRadius: "50%",
                      fontSize: "0.7rem",
                      position: "absolute",
                      height: "1rem",
                      width: "1rem",
                      top: 15,
                      left: "50%",
                      zIndex: "tooltip",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // zIndex: 1,
                    }}
                  >
                    {causa.notificaciones}
                  </Box>
                )}
              </Link>
            </Box>
          </TableCell>
          {getTipo() === "a recibir" && (
            <TableCell align="center">
              <UploadFileIcon
                cursor="pointer"
                color="info"
                style={{
                  fontSize: "30px",
                }}
                onClick={() => {
                  setCausaAIngresar(causa.id);
                  setMostrarIngresarCausa(true);
                }}
              />
            </TableCell>
          )}
        </TableRow>
      );
    });
  }

  return (
    <div>
      {mostrarIngresarCausa && (
        <causaContext.Provider value={{ setMostrarIngresarCausa }}>
          <IngresarCausa id={causaAIngresar} />
        </causaContext.Provider>
      )}
      <Box className="botonNuevaDemanda">
        <Buscador setBusqueda={setBusqueda} busqueda={busqueda} />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Nº de expediente</TableCell>
              <TableCell align="left">Carátula</TableCell>
              <TableCell align="left">Ubicación</TableCell>
              <TableCell align="center">Notificación</TableCell>
              {getTipo() === "a recibir" && (
                <TableCell align="center">Ingresar</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableSkeleton columnas={5} filas={rowsPerPage} />
            ) : (
              renderTable(causas)
            )}
          </TableBody>
          <TableFooter className="tablePaginado">
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                colSpan={5}
                count={total || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "Demandas por página",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablaPaginado}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TablaJuzgado;
