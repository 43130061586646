import React from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  restringirUsuarios,
  tiposPosibles,
} from "../../connections/decodeToken";
import TablaPaginado from "../../components/Tablas/TablaPaginado";
import renderToast from "../../components/Utils/RenderToast";
import NestedModal from "../../components/Alert&Toast/ModalEliminar";
import Buscador from "../../components/Buscador/Buscador.jsx";
import { obtenerUsuarios } from "../../connections/conexionesUsuario";
import {
  TablePagination,
  TableFooter,
  Table,
  Button,
  Box,
  Select,
  MenuItem,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import "../../routes/abogado/inicio/InicioAbogado.css";
import "../../components/Tablas/Tabla.css";
import { TableSkeleton } from "../TableSkeleton/TableSkeleton";

const TablaAdmin = () => {
  restringirUsuarios([tiposPosibles.admin]);
  const [render, setRender] = React.useState(0);
  const [status, setStatus] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate();
  const [filtroRol, setFiltroRol] = React.useState(4);
  const [filtroRola, setFiltroRola] = React.useState({});
  const [usuarios, setUsuarios] = React.useState([]);
  const [busqueda, setBusqueda] = React.useState("");
  const [totalUsuarios, setTotalUsuarios] = React.useState(0);
  const [filtros, setFiltros] = React.useState([
    `?limit=${rowsPerPage}`,
    "&",
    `offset=${page * rowsPerPage}`,
    "&",
    `order=asc`,
  ]);

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setFiltros([
      `?limit=${rowsPerPage}`,
      "&",
      `offset=${page * rowsPerPage}`,
      "&",
      `order=asc`,
      "&",
      `filter=${busqueda.length
        ? encodeURI(JSON.stringify({ search: busqueda }))
        : encodeURI(JSON.stringify(filtroRola))
      }`,
    ]);
  }, [page, rowsPerPage, busqueda, filtroRola]);
  //  --------------- Filtro por tipo de Usuario ---------------

  React.useEffect(() => {
    const filtrosQuery = filtros.join("");
    if (!usuarios.length || busqueda.length || !busqueda.length) {
      setLoading(true);
      async function cargarUsuarios() {
        const { data } = await obtenerUsuarios(filtrosQuery);
        setUsuarios(data.users || [{ info: "Not loaded" }]);
        setTotalUsuarios(data.total);
        setLoading(false);
      }
      cargarUsuarios();
    }
  }, [page, filtros, filtroRola, busqueda.length, usuarios.length]);

  React.useEffect(() => {
    if (status !== 0)
      setTimeout(() => {
        setStatus(0);
      }, 1000 * 1);
  }, [status]);

  /**
   * This useEffect is used for update after a delete action
   */
  React.useEffect(() => {
    // Should update table
    const filtrosQuery = filtros.join("");

    if (render !== 0) {
      setLoading(true);
      async function cargarUsuarios() {
        const { data } = await obtenerUsuarios(filtrosQuery);
        setUsuarios(data.users || [{ info: "Not loaded" }]);
        setTotalUsuarios(data.total);
        setLoading(false);
      }
      cargarUsuarios();
    }
  }, [render]);

  //  --------------- Paginado ---------------
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //  --------------- Esto es del Select de Filtro ---------------
  const ROLES = ["Abogado", "Juez", "Admin"];

  function filtrarRolUsuarios(e) {
    e.preventDefault();
    let tipo = "";
    switch (e.target.value) {
      case 1:
        tipo = "ABOGADO";
        break;
      case 2:
        tipo = "JUEZ";
        break;
      case 3:
        tipo = "ADMIN";
        break;
      default:
        tipo = "DEFAULT";
        break;
    }

    setFiltroRol(e.target.value);
    if (tipo === "DEFAULT") return setFiltroRola({});
    if (tipo.length) {
      setFiltroRola({ type: tipo });
    }
    setPage(0);
  }

  React.useEffect(() => {
    setFiltroRol(4);
    setFiltroRola({});
  }, [busqueda]);

  function mensajeToast() {
    switch (status) {
      case 200:
        return "Usuario eliminado con éxito";
      case 401:
        return "No tienes permisos para realizar esta acción";
      case 400:
        return "El id proporcionado configuró un error";
      case 404:
        return "El usuario solicitado ya no existe";
      default:
        return "Error interno del servidor, estamos trabajando para solucionarlo";
    }
  }

  function renderUsuarios(usuarios) {
    if (usuarios.info && usuarios.info === "Not loaded") {
      return (
        // <Box>
        //   <Nav />
        <Box
          sx={{
            backgroundColor: "rgba(0, 0, 0, 0.15)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress sx={{ width: "450px", height: "450px" }} />
          <h1>No encontramos usuarios</h1>
          <h4>
            Intente buscando una diferente, en el caso de que continúe el error,
            póngase en contacto con sistemas.
          </h4>
          `
          <Button
            variant="contained"
            color="info"
            onClick={(e) => navigate(-1)}
          >
            VOLVER ATRAS
          </Button>
        </Box>
        // </Box>
      );
    }

    return usuarios.map((usuario, index) => (
      <TableRow key={index} className="tableRowDocCausa">
        <TableCell align="left">{usuario.usuario}</TableCell>
        <TableCell align="left">{usuario.mail}</TableCell>
        <TableCell align="center">{usuario.type}</TableCell>
        <TableCell align="center">
          <Link to={`/editarusuario/${usuario.id}`}>
            <EditIcon color="info" />
          </Link>
        </TableCell>
        <TableCell align="center" sx={{ cursor: "pointer" }}>
          <NestedModal
            color="error"
            id={usuario.id}
            setPage={setPage}
            render={render}
            setRender={setRender}
          />
        </TableCell>
      </TableRow>
    ));
  }

  return (
    <div>
      {renderToast(status, mensajeToast())}
      <Box className="botonNuevaDemanda">
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <Buscador busqueda={busqueda} setBusqueda={setBusqueda} />
          <Select
            sx={{
              minWidth: 200,
              maxWidth: "400px",
              height: 40,
              border: 1,
              borderColor: "grey.500",
            }}
            labelId="demo-simple-select-helper-label"
            id="type"
            value={filtroRol}
            required
            onChange={(e) => filtrarRolUsuarios(e)}
            disabled={busqueda.length ? true : false}
          >
            {ROLES &&
              ROLES.map((funcion, index) => {
                return (
                  <MenuItem value={index + 1} key={index + 1}>
                    {funcion}
                  </MenuItem>
                );
              })}
            <MenuItem value={4}>Mostrar todos</MenuItem>
          </Select>
        </Box>
        <Box>
          <Button
            sx={{ mx: 2 }}
            variant="contained"
            color="info"
            onClick={() => navigate("/nuevousuario")}
          >
            Nuevo Usuario
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Usuario</TableCell>
              <TableCell align="left">Mail</TableCell>
              <TableCell align="center">Función</TableCell>
              <TableCell align="center">Editar</TableCell>
              <TableCell align="center">Eliminar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableSkeleton columnas={5} filas={rowsPerPage} />
            ) : (
              renderUsuarios(usuarios)
            )}
          </TableBody>
          <TableFooter className="tablePaginado">
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                colSpan={5}
                count={totalUsuarios || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage="Usuarios por página"
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablaPaginado}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};
export default TablaAdmin;
