import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { borrarUsuario } from '../../connections/conexionesUsuario';
import renderToast from '../../components/Utils/RenderToast';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '0px',
	borderRadius: '8px',
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 3,
};

export default function NestedModal({ id, setPage, render, setRender }) {
	const [open, setOpen] = React.useState(false);
	const [status, setStatus] = React.useState(0);

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	async function enviarEliminar(id) {
		const response = await borrarUsuario(id);
		setStatus(response.status);

		setTimeout(() => {
			setStatus(0);
		}, 1000 * 1);

		setOpen(false);
		setPage(0);
		setRender(render + 1);
	}

	function mensajeToast() {
		switch (status) {
			case 200:
				return 'Usuario eliminado con éxito';
			case 401:
				return 'No tienes permisos para realizar esta acción';
			case 400:
				return 'El id proporcionado configuró un error';
			case 404:
				return 'El usuario solicitado ya no existe';
			default:
				return 'Error interno del servidor, estamos trabajando para solucionarlo';
		}
	}

	return (
		<div>
			{renderToast(status, mensajeToast())}
			<DeleteOutlineIcon color='error' onClick={handleOpen} />
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby='parent-modal-title'
				aria-describedby='parent-modal-description'
			>
				<Box sx={{ ...style, width: 400 }}>
					<h2 id='parent-modal-title'>A continuación, usted podrá eliminar un usuario.</h2>
					<p id='parent-modal-description'>
						Tenga en cuenta que al eliminarlo, no podrá deshacer los cambios.
					</p>
					<Button variant='contained' color='error' onClick={() => enviarEliminar(id)}>
						Eliminar
					</Button>
				</Box>
			</Modal>
		</div>
	);
}
